//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {rzTabs, rzIconFlightStart, rzIconFlightEnd, rzSimpleTable, rzPreloader, rzAirlineIcon, rzButton, rzIconReturn} from 'razlet-ui';
import {stringDateToDate} from '~/utils';
import timeSrc from '~/assets/img/time.png';

export default {
  name: 'tablo',
  components: {
    rzTabs,
    rzIconFlightStart,
    rzIconFlightEnd,
    rzSimpleTable,
    rzPreloader,
    rzAirlineIcon,
    rzIconReturn,
    rzButton,
  },
  filters: {
    time(value) {
      const date = stringDateToDate(value);
      const hours = String(date.getHours());
      const minutes = String(date.getMinutes());

      const displayHours = hours.length < 2 ? `0${hours}` : hours;
      const displayMinutes = minutes.length < 2 ? `0${minutes}` : minutes;
      return `${displayHours}:${displayMinutes}`;
    },
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      timeSrc,
      tabs: [
        {
          title: 'Вылет',
          value: 'out',
          icon: 'rz-icon-flight-start',
        },
        {
          title: 'Прилет',
          value: 'in',
          icon: 'rz-icon-flight-end',
        },
      ],
      tableHead: [
        {name: 'Фактическое время'},
        {name: 'Направление'},
        {name: 'Авиакомпания'},
        {name: 'Статус'},
        {name: 'Запланированное время'},
      ],
      tableHeadMobile: [
        {name: 'Данные рейса'},
        {name: 'Время'},
      ],
      buttons: [
        {label: 'Вчера', value: 'yesterday'},
        {label: 'Сегодня', value: 'today'},
        {label: 'Завтра', value: 'tomorrow'},
      ],
      errorStatuses: ['cancel', 'delay'],
      statuses: {
        by_schedule: 'По расписанию',
        complete: 'Рейс завершен',
        delay: 'Отложен',
        cancel: 'Отменен',
        registration: 'Началась регистрация',
      },
      activeTab: 'out',
      selectedDay: 'today',
    };
  },
  methods: {
    getTablo() {
      this.$emit('refresh', this.selectedDay);
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    changeDay(day) {
      this.selectedDay = day;
      this.getTablo();
    },
  },
};
